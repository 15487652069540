import React from 'react';
import './CateringDelivery.css';

export default function CateringDelivery() {
  return (
    <div className="catering-delivery-page">
      <header className="header-section">
        <div className="header-image-container">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F452050149_808514851426193_1679363225595272529_n.jpg?alt=media&token=a4de845c-04bf-46a0-8e32-7fe3e7cf2534" 
            alt="Catering Delivery" 
            className="header-image" 
          />
          <div className="header-overlay">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2FWave%20Logo%20Catering%20Trans%20(1).png?alt=media&token=001bf529-fcf3-4dcc-8776-da23ef3539c9" 
              alt="Wave Catering Delivery Logo" 
              className="header-logo" 
            />
            <h1 className="header-title">Elevate Your Catering Experience</h1>
            <p className="header-subtitle">Professional delivery solutions that ensure your culinary creations reach their destination perfectly, every time.</p>
            <a href="https://calendly.com/waveoutreach/30min" className="header-cta">
              <i className="fas fa-calendar-check mr-2"></i>
              Schedule Your Consultation
            </a>
          </div>
        </div>
      </header>

      <section className="use-cases-section">
        <div className="container">
          <div className="section-header">
            <h2>Tailored Solutions for Your Business</h2>
            <p>Discover how Wave's specialized catering delivery services can transform your operations</p>
          </div>
          <div className="use-cases">
            <div className="use-case">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F454352183_816229790654699_1226448383480439228_n.jpg?alt=media&token=7eba7143-f323-437c-ba98-31df143e56d4" 
                alt="Restaurant Service" 
                className="use-case-image" 
              />
              <div className="use-case-content">
                <h3>Restaurants & Caterers</h3>
                <p>Elevate your delivery service with our professional solutions designed specifically for food service providers.</p>
                <ul>
                  <li>Custom setup services ensuring your presentation standards</li>
                  <li>Dedicated communication channels between all parties</li>
                  <li>Flexible delivery management for growth</li>
                  <li>Professional brand representation</li>
                </ul>
              </div>
            </div>
            <div className="use-case">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F454450945_818048877139457_7733263436355115951_n.jpg?alt=media&token=7dfad811-0b3a-4e16-9b80-ede8beee5011" 
                alt="Corporate Catering" 
                className="use-case-image" 
              />
              <div className="use-case-content">
                <h3>Corporate & Healthcare Facilities</h3>
                <p>White-glove delivery service tailored for professional environments.</p>
                <ul>
                  <li>Dedicated driver assignments for consistency</li>
                  <li>Precise timing and coordination</li>
                  <li>Professional setup and presentation</li>
                  <li>Healthcare facility protocols compliance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="commitment-section">
        <div className="container">
          <div className="section-header">
            <h2>Our Commitment to Excellence</h2>
            <p>Experience the Wave difference through our dedicated approach to service</p>
          </div>
          <div className="commitment-grid">
            <div className="commitment-item">
              <i className="fas fa-headset"></i>
              <h3>Proactive Communication</h3>
              <p>Team-based approach with automation assistance ensuring timely, high-quality deliveries with a human touch.</p>
            </div>
            <div className="commitment-item">
              <i className="fas fa-shield-alt"></i>
              <h3>Unmatched Accountability</h3>
              <p>Taking complete responsibility for our work with a focus on quality, reliability, and professionalism.</p>
            </div>
            <div className="commitment-item">
              <i className="fas fa-utensils"></i>
              <h3>Expert Handling & Setup</h3>
              <p>Our drivers are trained in catering best practices, ensuring every order arrives intact and ready for seamless setup—so you can focus on serving guests, not managing deliveries.</p>
            </div>
            <div className="commitment-item">
              <i className="fas fa-calendar-check"></i>
              <h3>Flexible Scheduling & Coordination</h3>
              <p>From advance bookings to on-the-fly adjustments, our dedicated support team works with you to coordinate deliveries around your timeline—keeping your event running smoothly, every time.</p>
            </div>
            <div className="commitment-item">
              <i className="fas fa-bolt"></i>
              <h3>Rapid Response</h3>
              <p>Dedicated dispatch and intake specialists ready to assist drivers, riders, and restaurants.</p>
            </div>
            <div className="commitment-item">
              <i className="fas fa-check-circle"></i>
              <h3>Insured Operations</h3>
              <p>Comprehensive third-party insurance coverage for all drivers, with additional COI for partners.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-section">
        <div className="container">
          <div className="section-header">
            <h2>How We Deliver Excellence</h2>
            <p>Our proven process ensures consistent, high-quality service delivery</p>
          </div>
          <div className="service-details-grid">
            <div className="service-detail-item">
              <h3><i className="fas fa-expand-arrows-alt mr-2"></i>Expanding Your Reach</h3>
              <p>We act as an extension of your business, with trained drivers who understand your specific setup requirements and brand standards.</p>
            </div>
            <div className="service-detail-item">
              <h3><i className="fas fa-clock mr-2"></i>Flexible Scheduling</h3>
              <p>24-hour notice preferred, but we accommodate rush orders when possible. All we need is complete order information on a single invoice.</p>
            </div>
            <div className="service-detail-item">
              <h3><i className="fas fa-comments mr-2"></i>Seamless Communication</h3>
              <p>Our operations team manages all communication between restaurants, drivers, and customers, with real-time tracking capabilities.</p>
            </div>
            <div className="service-detail-item">
              <h3><i className="fas fa-file-contract mr-2"></i>Simple Setup Process</h3>
              <p>After a brief consultation, we provide a clear MOU outlining our competitive pricing and service terms.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="container">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2FWave%20Logo%20Catering%20Trans%20(1).png?alt=media&token=001bf529-fcf3-4dcc-8776-da23ef3539c9" 
            alt="Wave Catering Delivery Logo" 
            className="contact-logo" 
          />
          <div className="contact-content">
            <h2 style={{ 
              color: '#ffffff',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
              fontSize: '2.5rem',
              fontWeight: '700'
            }}>Ready to Transform Your Delivery Service?</h2>
            <p className="highlighted-contact" style={{
              color: '#ffffff',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
              marginTop: '30px'
            }}>
              <span style={{ color: '#ffffff' }}>Call us at </span>
              <a href="tel:18339283663" className="contact-link" style={{
                color: '#ffffff',
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
                fontWeight: '700'
              }}>1-833-WAVE2ME</a>
            </p>
            <p style={{
              color: '#ffffff',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
              fontSize: '1.2rem',
              marginTop: '20px'
            }}>Let's discuss how we can help streamline your catering delivery operations.</p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <div className="container">
          <div className="cta-content">
            <h2>Start Your Journey with Wave</h2>
            <p>Transform your catering delivery experience with our professional services.</p>
            <a href="https://calendly.com/waveoutreach/30min" className="cta-button">
              <i className="fas fa-calendar-alt mr-2"></i>
              Schedule Your Consultation
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
