import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import uniqBy from 'lodash/uniqBy';
import React, {useEffect, useState} from 'react';
import WhiteText from '../../components/WhiteText';
import filterBoolean from '../../functions/filterBoolean';
import useStatus from '../../hooks/useStatus';
import Funnel from '../../models/fountain/Funnel';
import LocationGroup from '../../models/fountain/LocationGroup';
import ScoopMApi from '../../references/scoopm-api';

// Add type declaration for the custom element
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'zapier-interfaces-chatbot-embed': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          'is-popup'?: string;
          'chatbot-id'?: string;
          'is-open'?: string;
          'start-open'?: string;
          'default-open'?: string;
          'auto-open'?: string;
        },
        HTMLElement
      >;
    }
  }
}

type GroupWithFunnels = LocationGroup & {funnels?: Funnel[]};

export default function Openings() {
  // const {handlePromise, status, error} = useStatus();
  // const [groups, setGroups] = useState<GroupWithFunnels[]>();
  
  // State for FAQ accordion
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  const [activeFaqCategory, setActiveFaqCategory] = useState<'business' | 'driver'>('driver');
  
  // Toggle accordion function
  const toggleAccordion = (id: string) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  // Toggle all accordions
  const toggleAllAccordions = () => {
    if (activeAccordion) {
      // If any accordion is open, close all
      setActiveAccordion(null);
    } else {
      // If all accordions are closed, open all in current category
      const currentFaqs = activeFaqCategory === 'business' ? businessFaqs : driverFaqs;
      // We're setting a special value to indicate "all open"
      setActiveAccordion('all');
    }
  };

  // Check if an accordion should be open
  const isAccordionOpen = (id: string) => {
    return activeAccordion === 'all' || activeAccordion === id;
  };

  // Add useEffect for chatbot script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Business FAQs data
  const businessFaqs = [
    {
      id: 'faq1',
      question: 'What is Wave\'s primary service offering?',
      answer: 'Wave provides seamless catering delivery solutions, specializing in setting up and delivering food to end customers. We also offer rideshare services, non-emergency medical transport, and delivery for non-profits.'
    },
    {
      id: 'faq2',
      question: 'What markets does Wave serve?',
      answer: (
        <>
          <p>Wave targets several markets, including the $113B rideshare industry, $1T online food delivery sector, non-emergency medical transport, and non-profit organizations. We focus on underserved niches and local communities to expand our services.</p>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card mb-3 border-0 shadow-sm">
                <div className="card-header bg-primary text-white">
                  <strong>Rideshare</strong>
                </div>
                <div className="card-body">
                  <ul className="mb-0">
                    <li>Charlotte, NC Metro Region</li>
                    <li>Atlanta, GA Metro Region</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card border-0 shadow-sm">
                <div className="card-header bg-primary text-white">
                  <strong>Delivery</strong>
                </div>
                <div className="card-body">
                  <ul className="mb-0">
                    <li>Charlotte, NC</li>
                    <li>Atlanta, GA</li>
                    <li>Dallas, TX</li>
                    <li>Austin, TX</li>
                    <li>Los Angeles, CA</li>
                    <li>San Francisco, CA</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      id: 'faq3',
      question: 'How does Wave differentiate from competitors like Uber and DoorDash?',
      answer: 'Wave offers a superior customer experience through trained drivers, a focus on safety, and proprietary AI algorithms that improve delivery efficiency. Unlike competitors, we provide direct communication between restaurants, drivers, and customers, ensuring accountability.'
    },
    {
      id: 'faq4',
      question: 'What are Wave\'s operational hours?',
      answer: 'Wave\'s drivers are available from 6 AM to 10 PM EST, with operational support during these hours and an answering service after hours. Orders should ideally be submitted by 5 PM EST for next-day deliveries.'
    },
    {
      id: 'faq5',
      question: 'How far does Wave\'s delivery service reach?',
      answer: 'Drivers typically cover a range of up to 10 miles, with an additional $1.50 per mile charge for distances beyond that. Wave can manage longer deliveries by incentivizing drivers through extra compensation.'
    },
    {
      id: 'faq6',
      question: 'What technology does Wave use for communication?',
      answer: 'Wave relies on Slack for real-time communication between drivers, restaurants, and operations. Clients are granted access to Slack channels to communicate directly with the dispatch team, ensuring transparency and responsiveness.'
    },
    {
      id: 'faq7',
      question: 'How does Wave handle urgent or same-day orders?',
      answer: 'While Wave prioritizes next-day orders, we accept same-day requests and strive to fulfill them on a best-efforts basis. We cannot guarantee coverage for last-minute requests but do everything possible to accommodate them.'
    },
    {
      id: 'faq8',
      question: 'How does Wave ensure delivery quality and driver accountability?',
      answer: 'Wave has a strict quality control process that includes driver training, real-time communication, and post-delivery photos. Drivers are equipped with insulated bags, and all deliveries are closely monitored for timeliness and accuracy.'
    },
    {
      id: 'faq9',
      question: 'How are invoices processed?',
      answer: 'Wave sends weekly invoices through QuickBooks, detailing all deliveries made from Monday to Sunday. Payment is due within 7 days (Net 7), and invoices include any additional charges such as wait times or mileage.'
    },
    {
      id: 'faq10',
      question: 'What makes Wave an attractive option for restaurants?',
      answer: 'Wave offers flexibility with delivery services, handling overflow for restaurants that can\'t meet demand. We provide trained drivers who can set up food according to restaurant preferences, ensuring a seamless experience for both the business and customers.'
    },
    {
      id: 'faq11',
      question: 'How does Wave ensure driver retention and welfare?',
      answer: 'We prioritize driver welfare by offering competitive wages, insurance coverage, and tools like schedule management via our app. Drivers also receive tips and additional compensation for longer trips, which helps maintain high retention rates.'
    },
    {
      id: 'faq12',
      question: 'How does Wave handle data security and privacy?',
      answer: 'Wave employs strong encryption methods, regular security audits, and complies with GDPR and other privacy regulations to ensure user data is secure.'
    },
    {
      id: 'faq13',
      question: 'What is Wave\'s pricing model for catering delivery services?',
      answer: (
        <>
          <p>Wave charges based on the value of the catering order.</p>
          <div className="card mb-3 border-0 shadow-sm">
            <div className="card-header bg-primary text-white">
              <strong>MidWest and Western Markets</strong>
            </div>
            <div className="card-body">
              <ul>
                <li>Orders under $300: $45</li>
                <li>Orders between $300 and $699: $55</li>
                <li>Orders between $700 and $999: $65</li>
                <li>Orders over $1000: 10% of the total</li>
              </ul>
            </div>
          </div>
          <div className="card mb-3 border-0 shadow-sm">
            <div className="card-header bg-primary text-white">
              <strong>Eastern Markets</strong>
            </div>
            <div className="card-body">
              <ul>
                <li>Orders under $300: $40</li>
                <li>Orders between $300 and $699: $50</li>
                <li>Orders between $700 and $999: $60</li>
                <li>Orders over $1000: 10% of the total</li>
              </ul>
            </div>
          </div>
          <p>For distances over 10 miles, an additional $1.50 per mile is applied. Tips, if any, are kept by Wave.</p>
        </>
      )
    },
    {
      id: 'faq14',
      question: 'Does Wave offer insurance coverage for deliveries?',
      answer: 'Yes, Wave provides third-party insurance coverage for all its drivers, offering restaurants additional peace of mind. We also supply Certificates of Insurance (COIs) to restaurant partners, further ensuring that all parties are protected.'
    },
    {
      id: 'faq15',
      question: 'How does Wave handle wait time charges?',
      answer: 'Wave applies additional fees for wait times. For example, if a driver waits 30–45 minutes, there is a $20 fee. Wait times of 46–60 minutes incur a $25 fee, and waits longer than 61 minutes incur a $30 fee.'
    },
    {
      id: 'faq16',
      question: 'Can Wave manage corporate and large catering orders?',
      answer: 'Yes, Wave is fully equipped to handle large corporate catering orders. Drivers are trained in setup, and we provide flexible scheduling. For very large orders, we assign multiple drivers or rent additional transport vehicles to ensure smooth delivery.'
    },
    {
      id: 'faq17',
      question: 'How does Wave address issues during delivery?',
      answer: 'If a delivery is delayed or there is an issue with the driver, Wave\'s operations team communicates directly with the client through Slack or other preferred communication methods. We resolve problems in real time, ensuring any issues are addressed promptly.'
    },
    {
      id: 'faq18',
      question: 'What are Wave\'s growth and expansion plans?',
      answer: 'Wave is expanding its services into new cities, with plans to enhance delivery offerings like "WaveBites" (grocery and retail delivery). We are also growing our rideshare services, targeting markets such as non-emergency medical transport.'
    },
    {
      id: 'faq19',
      question: 'How does Wave handle client onboarding and consultation?',
      answer: 'Wave conducts detailed onboarding meetings with new clients to align on operational processes and delivery expectations. We provide consultations before formalizing the relationship through a Memorandum of Understanding (MOU), which outlines pricing and service specifics.'
    },
    {
      id: 'faq20',
      question: 'How does Wave support non-profit and community-based transport services?',
      answer: 'Wave offers specialized rideshare services for non-profit organizations, focusing on non-emergency medical transport and elderly transport. We emphasize community support, providing safe, reliable transport services to underserved groups.'
    },
    {
      id: 'faq21',
      question: 'What technological innovations are integrated into Wave\'s operations?',
      answer: 'Wave uses proprietary AI algorithms to enhance route optimization and predict delivery demand. We also provide real-time tracking and communication via Slack as well as AI automations to improve operational efficiency and ensure clients are constantly updated.'
    },
    {
      id: 'faq22',
      question: 'How does Wave ensure driver safety and service quality?',
      answer: 'Wave ensures drivers are well-trained and properly equipped with insulated bags and carts. Drivers must adhere to a dress code and maintain cleanliness. The company also employs AI-enhanced background checks to ensure passenger and client safety.'
    },
    {
      id: 'faq23',
      question: 'Can clients track their delivery in real-time?',
      answer: 'Yes, Wave provides real-time driver tracking capabilities. Clients can view the driver\'s progress and receive updates about delivery status via our proprietary internal portal.'
    },
    {
      id: 'faq24',
      question: 'How does Wave handle billing and payment for services?',
      answer: 'Wave invoices clients weekly, detailing completed deliveries along with any extra fees for mileage, wait times, or tips. Payments are due within 7 days of receiving the invoice (Net 7 terms).'
    },

    {
      id: 'faq25',
      question: 'What customer support options does Wave provide?',
      answer: 'Wave offers a proactive customer service approach, utilizing dedicated dispatch and intake teams to address client concerns. Our Slack-based communication allows for real-time updates and quick problem resolution.'
    },
    {
      id: 'faq26',
      question: 'What is Wave\'s approach to sustainability?',
      answer: 'Wave is exploring environmentally friendly options, such as integrating electric vehicles into its delivery fleet, to reduce its carbon footprint. We are also promoting eco-friendly practices within the rideshare and food delivery sectors.'
    },
    {
      id: 'faq27',
      question: 'What types of businesses benefit most from Wave\'s services?',
      answer: 'Wave primarily serves restaurants that either lack their own delivery services or cannot meet delivery demand. We also work with corporate catering clients, healthcare facilities, and non-profit organizations needing reliable transport and delivery solutions.'
    },
    {
      id: 'faq28',
      question: 'Does Wave work with third-party delivery platforms?',
      answer: 'While Wave primarily handles direct-to-restaurant deliveries, nothing in our agreements prevents restaurants from also using third-party platforms like UberEats or DoorDash. However, Wave aims to provide superior, dedicated delivery services.'
    },
    {
      id: 'faq29',
      question: 'What is Wave\'s approach to handling multiple projects or clients at once?',
      answer: 'Wave has a robust dispatch and operations system that allows us to manage multiple clients and delivery projects simultaneously. Processes are standardized across all clients to reduce errors and ensure smooth, high-quality service.'
    },
    {
      id: 'faq30',
      question: 'What are Wave\'s future plans for innovation?',
      answer: 'Wave is investing in cutting-edge technologies, including machine learning and AI, to continue optimizing delivery routes and improving service. We also aim to explore autonomous vehicle technology to further enhance our delivery capabilities.'
    }
  ];

  // Driver FAQs data
  const driverFaqs = [
    {
      id: 'driver1',
      question: 'What are the basic requirements to become a Wave driver?',
      answer: 'To become a Wave driver, you must have a valid driver\'s license, a reliable vehicle, and proof of insurance. Additionally, drivers are required to have a minimum of two XL insulated transporting bags for deliveries as well as either a cart or wagon.'
    },
    {
      id: 'driver2',
      question: 'How do Wave drivers receive and complete delivery orders?',
      answer: 'Drivers are assigned delivery orders through Wave\'s dispatch system. Orders are communicated in real-time through the Slack workspace, where drivers can receive details about pickup, drop-off locations, and any special instructions. In addition, text confirmations are sent out the day before and following on the day of delivery or ride.'
    },
    {
      id: 'driver3',
      question: 'What type of vehicle do I need?',
      answer: 'While any reliable vehicle is acceptable, Wave prefers larger vehicles for catering orders. Drivers handling large orders may need to have a cart or wagon to transport bigger packages efficiently.'
    },
    {
      id: 'driver4',
      question: 'How do I identify myself when picking up orders?',
      answer: 'Wave drivers are required to introduce themselves as Wave drivers when picking up an order.'
    },
    {
      id: 'driver5',
      question: 'How do I handle wait times during delivery?',
      answer: 'Wave compensates drivers for extended wait times. If you wait between 30-45 minutes for an order, you will receive a nominal fee based on the order total, which increases for longer wait periods. Ensure you communicate any delays to dispatch, and the operations team will keep the client updated.'
    },
    {
      id: 'driver6',
      question: 'How far will I be expected to travel for deliveries?',
      answer: 'Wave drivers typically travel up to 10 miles from the pickup location. For deliveries beyond this distance, you will receive an additional $1.50 per mile to cover the extra mileage. This incentive encourages drivers to take longer trips when necessary.'
    },
    {
      id: 'driver7',
      question: 'What is Wave\'s policy on tips?',
      answer: 'Cash tips are highly encouraged and are a direct form of compensation for drivers. Any tips received electronically will be pooled amongst all drivers who worked that day and then distributed to the driver in the normal pay cycle, clients are given options to tip either via phone or online when placing orders.'
    },
    {
      id: 'driver8',
      question: 'What training does Wave provide for drivers?',
      answer: 'Wave provides onboarding training for all drivers, covering everything from customer service to safe food handling and delivery setup. Experienced drivers may also take part in ride-alongs to get hands-on experience before starting solo deliveries.'
    },
    {
      id: 'driver9',
      question: 'What should I do if I encounter an issue during a delivery?',
      answer: 'If you face any problems, such as traffic delays or order complications, notify the operations team immediately via Slack or call the dispatch team directly. Real-time communication is critical to ensure that clients are updated promptly.'
    },
    {
      id: 'driver10',
      question: 'How do I get paid for my deliveries?',
      answer: 'Wave processes payments weekly through your preferred payment method. You will receive payment for all completed deliveries from the previous week, including any tips and additional fees for wait times or extra mileage.'
    },
    {
      id: 'driver11',
      question: 'Do I need insurance to drive for Wave?',
      answer: 'Yes, you must have valid auto insurance. However, Wave also provides third-party insurance coverage for additional protection when you\'re actively making deliveries.'
    },
    {
      id: 'driver12',
      question: 'What happens if there is a delay with a delivery?',
      answer: 'If a delay occurs due to factors beyond your control, such as traffic or a late order from the restaurant, ensure you notify the operations team immediately. Wave will communicate with the client to resolve any issues, and you will be compensated for significant delays.'
    },
    {
      id: 'driver13',
      question: 'How do I maintain quality standards during delivery?',
      answer: 'Wave expects all drivers to follow strict guidelines regarding professionalism, such as wearing clean, appropriate clothing and using insulated bags to keep food fresh. After each delivery, take a photo to confirm completion and submit it via Slack.'
    },
    {
      id: 'driver14',
      question: 'When do I get paid?',
      answer: 'Paydays are every Friday - Monday. Payouts begin on Friday and continue throughout the weekend until all drivers have been paid.'
    },
    {
      id: 'driver15',
      question: 'How long does it take to become a driver?',
      answer: (
        <>
          <p>From the time you start your application, until you complete onboarding and are a hired driver; the times will vary depending on a number of factors:</p>
          <ul>
            <li>All documentation is in order</li>
            <li>Onboarding documents are signed</li>
            <li>You have completed your orientation</li>
            <li>You have chosen one of the paid tiers of background check: Expedited (2 - 3 weeks) or Priority: (7 days)</li>
            <li>Have successfully logged into the Wave app upon completion of your background check and have been sent confirmation to sign into the app.</li>
          </ul>
          <p>Once all these have been completed, you are now ready to start.</p>
        </>
      )
    },
    {
      id: 'driver16',
      question: 'How will I know what stage of the application I am in?',
      answer: 'You will receive emails and text messages alerting you to what stage of the application you are in. You can also check the status of your application by going to waveride.co/openings and using the chatbot at the bottom right hand corner of your screen. Simply ask, "What stage of the application am I in?" and give it your name and email address. The bot will let you know where you are and what are next steps.'
    },
    {
      id: 'driver17',
      question: 'What happens if I fail my background check?',
      answer: 'Upon failing your background check, you will be disqualified. You will receive an email alerting you to this decision and you will no longer be considered for the position. If you know you will fail your background check, please do not apply.'
    },
    {
      id: 'driver18',
      question: 'What happens if I fail my vehicle inspection?',
      answer: 'If you fail your vehicle inspection, you will be given the option to continue as a catering delivery driver, or reschedule your inspection at a later date. Please be sure to bring your Drivers License, Registration and proof of insurance to your inspection. Ensure your vehicle is clean and free of clutter inside, and make sure your vehicle is safe to operate.'
    }
  ];

  // Business FAQ Categories
  const businessCategories = {
    general: {
      title: '🏢 General Information',
      faqs: ['faq1', 'faq2', 'faq3']
    },
    operations: {
      title: '⚙️ Operations',
      faqs: ['faq4', 'faq5', 'faq6', 'faq7', 'faq8']
    },
    pricing: {
      title: '💰 Pricing & Billing',
      faqs: ['faq9', 'faq13', 'faq15', 'faq24']
    },
    technology: {
      title: '💻 Technology & Innovation',
      faqs: ['faq21', 'faq23', 'faq30']
    },
    quality: {
      title: '✨ Quality & Safety',
      faqs: ['faq8', 'faq12', 'faq14', 'faq22']
    },
    growth: {
      title: '📈 Growth & Future',
      faqs: ['faq18', 'faq26', 'faq27', 'faq28', 'faq29']
    }
  };

  // Driver FAQ Categories
  const driverCategories = {
    requirements: {
      title: '📋 Basic Requirements',
      faqs: ['driver1', 'driver3', 'driver11', 'driver17', 'driver18']
    },
    onboarding: {
      title: '🚀 Getting Started',
      faqs: ['driver15', 'driver16', 'driver8']
    },
    operations: {
      title: '🚗 Daily Operations',
      faqs: ['driver2', 'driver4', 'driver5', 'driver6', 'driver9', 'driver12', 'driver13']
    },
    compensation: {
      title: '💵 Pay & Benefits',
      faqs: ['driver7', 'driver10', 'driver14']
    }
  };

  const renderFAQSection = (categoryData: any, faqs: any[]) => {
    return Object.entries(categoryData).map(([key, category]: [string, any]) => (
      <div key={key} className="mb-4">
        <h4 className="mb-3" style={{ 
          color: '#00B6D9',
          fontSize: '1.2rem',
          borderBottom: '2px solid #e6f7fb',
          paddingBottom: '0.5rem'
        }}>
          {category.title}
        </h4>
        <div className="accordion">
          {category.faqs.map((faqId: string) => {
            const faq = faqs.find(f => f.id === faqId);
            if (!faq) return null;
            return (
              <div key={faq.id} className="accordion-item mb-2 border-0" style={{ backgroundColor: 'transparent' }}>
                <h2 className="accordion-header">
                  <button 
                    className={`accordion-button text-start ${isAccordionOpen(faq.id) ? '' : 'collapsed'}`}
                    type="button" 
                    onClick={() => toggleAccordion(faq.id)}
                    style={{ 
                      backgroundColor: isAccordionOpen(faq.id) ? '#e6f7fb' : 'white',
                      color: '#666',
                      fontWeight: '500',
                      fontSize: '0.95rem',
                      border: 'none',
                      boxShadow: 'none',
                      borderRadius: '8px',
                      padding: '12px 15px'
                    }}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div className={`accordion-collapse collapse ${isAccordionOpen(faq.id) ? 'show' : ''}`}>
                  <div className="accordion-body" style={{ 
                    backgroundColor: '#f8f9fa',
                    borderRadius: '0 0 8px 8px',
                    fontSize: '0.9rem',
                    lineHeight: '1.6'
                  }}>
                    {faq.answer}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ));
  };

  return (
    <div className="container py-2 py-md-3">
      <h1 className="display-2 mb-0">Drive for Wave</h1>
      <br />

      <h2>Rideshare and Delivery Driver Applicant Portal</h2>
      <a target="_blank" style={{color: '#00B6D9'}} href="https://certified.waveride.co/" rel="noreferrer">Click here to apply!</a>
      <br/>
      <br/>

      {/* Add chat section with more visibility */}
      <div className="mt-4 p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
        <h3>Have Questions? Chat With Us! 💬</h3>
        <p className="mb-3">Our AI assistant is here to help answer your questions about driving with Wave. Look for the chat icon in the bottom right corner!</p>
        <zapier-interfaces-chatbot-embed 
          is-popup='true' 
          chatbot-id='cm2ahngqr0019we3v3l5xc3w8'
        />
      </div>

      {/* FAQ Section */}
      <div className="mt-5">
        <h2 className="mb-4 text-center" style={{ color: '#00B6D9', fontWeight: 'bold' }}>FAQ About Wave Mobile Technologies</h2>
        
        {/* FAQ Category Tabs */}
        <div className="d-flex justify-content-center mb-4">
          <div className="btn-group" role="group" aria-label="FAQ Categories">
            <button 
              type="button" 
              className={`btn ${activeFaqCategory === 'driver' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => {
                setActiveFaqCategory('driver');
                setActiveAccordion(null);
              }}
              style={{
                borderRadius: '20px 0 0 20px',
                padding: '8px 20px'
              }}
            >
              Driver Questions
            </button>
            <button 
              type="button" 
              className={`btn ${activeFaqCategory === 'business' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => {
                setActiveFaqCategory('business');
                setActiveAccordion(null);
              }}
              style={{
                borderRadius: '0 20px 20px 0',
                padding: '8px 20px'
              }}
            >
              Business Facts
            </button>
          </div>
        </div>
        
        {/* Business FAQs */}
        {activeFaqCategory === 'business' && (
          <div className="row">
            <div className="col-12">
              <div className="p-3" style={{ backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
                {renderFAQSection(businessCategories, businessFaqs)}
              </div>
            </div>
          </div>
        )}
        
        {/* Driver FAQs */}
        {activeFaqCategory === 'driver' && (
          <div className="row">
            <div className="col-12">
              <div className="p-3" style={{ backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
                {renderFAQSection(driverCategories, driverFaqs)}
              </div>
            </div>
          </div>
        )}
        
        {/* View All FAQs Button */}
        <div className="text-center mt-4 mb-5">
          <button 
            className="btn" 
            type="button" 
            onClick={toggleAllAccordions}
            style={{
              backgroundColor: '#00B6D9',
              color: 'white',
              fontWeight: '500',
              padding: '10px 25px',
              borderRadius: '30px',
              border: 'none',
              boxShadow: '0 4px 6px rgba(0, 182, 217, 0.2)',
              fontSize: '0.9rem'
            }}
          >
            {activeAccordion ? 'Collapse All' : 'Expand All'}
          </button>
        </div>
      </div>

      {/*  {error ? (
        <div className="alert alert-warning">Unable to list openings at this time</div>
      ) : groups ? (
        groups.map(group => (
          <div key={group.id} className="mb-3">
            <h1 className="display-4 text-primary mb-2">{group.name}</h1>
            {group.funnels?.map(funnel => (
              <div key={funnel.id} className="mb-1">
                <a href={funnel.apply_url} target="_blank" rel="noopener noreferrer">
                  {funnel.title}
                </a>
              </div>
            )) ?? <span className="text-muted">No openings at this time</span>}
          </div>
        ))
      ) : (
        times(2, i => (
          <div key={i} style={{width: '350px'}} className="mb-3">
            <div className="loading-animation">
              <h1 className="display-4 mb-2">
                <WhiteText />
              </h1>
            </div>
            {times(12, j => (
              <div key={j} className="loading-animation mb-1">
                <WhiteText />
              </div>
            ))}
          </div>
        ))
      )}

      {groups && (
        <>
          <hr />
          <div className="mt-4 mb-5">
            <small className="text-muted">
              You'll fill out your application on the secure site of our our partner,{' '}
              <a href="https://get.fountain.com/" target="_blank" rel="noopener noreferrer">
                Fountain
              </a>
            </small>
          </div>
        </>
      )}
      */}
    </div>
  );
}

// async function getFunnels(page = 0): Promise<Funnel[]> {
//   const response = await ScoopMApi.instance.fountain.funnels.list(page);
//   if (response.data.pagination.current < response.data.pagination.last) {
//     return [...response.data.funnels, ...(await getFunnels(page + 1))];
//   }
//   return response.data.funnels;
// }

