import React from 'react';
import './ProfessionalServices.css';

export default function ProfessionalServices() {
  return (
    <div className="professional-services-page">
      {/* Hero Section */}
      <div className="hero-section">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/Professional-Services%2Flogo_words_trans%20(1).png?alt=media&token=40e4f54e-1e7b-4390-b513-b2bfe357dc36"
          alt="Professional Services Logo"
          className="hero-logo"
        />
        <h1 className="hero-title">Professional Services</h1>
        <p className="hero-subtitle">
          Specialized solutions for transportation and logistics professionals, backed by years of industry expertise.
        </p>
      </div>

      {/* Services Grid */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <i className={`fas ${service.icon} service-icon`}></i>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>

      {/* AI & Automation Section */}
      <div className="ai-automation-section" style={{
        backgroundColor: '#f8f9fa',
        padding: '4rem 2rem',
        marginTop: '3rem',
        borderRadius: '12px',
      }}>
        <div className="container">
          <h2 className="text-center mb-4" style={{ color: '#00B6D9' }}>AI Agents & Workflow Automation</h2>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body">
                  <h3 className="card-title">
                    <i className="fas fa-robot mr-2" style={{ color: '#00B6D9' }}></i>
                    Custom AI Agents
                  </h3>
                  <ul className="list-unstyled">
                    <li className="mb-2">✓ Intelligent customer service bots</li>
                    <li className="mb-2">✓ Natural language processing for communications</li>
                    <li className="mb-2">✓ Predictive analytics for business operations</li>
                    <li className="mb-2">✓ Automated decision-making systems</li>
                    <li className="mb-2">✓ Real-time data analysis and reporting</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body">
                  <h3 className="card-title">
                    <i className="fas fa-cogs mr-2" style={{ color: '#00B6D9' }}></i>
                    Workflow Automation
                  </h3>
                  <ul className="list-unstyled">
                    <li className="mb-2">✓ Business process automation</li>
                    <li className="mb-2">✓ Integration with popular platforms (Zapier, Make, etc.)</li>
                    <li className="mb-2">✓ Custom workflow development</li>
                    <li className="mb-2">✓ Automated reporting and notifications</li>
                    <li className="mb-2">✓ Process optimization and monitoring</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <a href="https://calendly.com/waveoutreach/wave-professional-services" 
               className="btn btn-primary"
               style={{
                 backgroundColor: '#00B6D9',
                 border: 'none',
                 padding: '12px 30px',
                 borderRadius: '30px',
                 fontWeight: '500'
               }}>
              <i className="fas fa-robot mr-2"></i>
              Discuss Your AI & Automation Needs
            </a>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="contact-section">
        <h2 style={{ color: '#ffffff' }}>Ready to Get Started?</h2>
        <p style={{ color: 'rgba(255, 255, 255, 0.9)' }}>Contact us to discuss how we can help your business succeed.</p>
        <a href="https://calendly.com/waveoutreach/wave-professional-services" className="btn btn-primary">
          <i className="fas fa-calendar-alt mr-2"></i>
          Schedule a Consultation
        </a>
      </div>
    </div>
  );
}

const services = [
  {
    title: "Enterprise Software Development",
    description: "Custom software solutions designed for the transportation and logistics industry, including fleet management systems and dispatch platforms.",
    icon: "fa-laptop-code"
  },
  {
    title: "Mobile Development",
    description: "Native and cross-platform mobile applications for drivers, dispatchers, and customers, with real-time tracking and communication features.",
    icon: "fa-mobile-alt"
  },
  {
    title: "AI & Machine Learning",
    description: "Intelligent solutions for route optimization, demand prediction, and automated dispatch systems.",
    icon: "fa-brain"
  },
  {
    title: "Blockchain Development",
    description: "Secure and transparent solutions for supply chain management, smart contracts, and digital payments.",
    icon: "fa-link"
  },
  {
    title: "Cloud Services",
    description: "Scalable cloud infrastructure design and implementation for transportation and logistics operations.",
    icon: "fa-cloud"
  },
  {
    title: "Industry Solutions",
    description: "Specialized solutions for insurance, financial services, automotive, and medical transportation sectors.",
    icon: "fa-industry"
  },
  {
    title: "AI Agents & Automation",
    description: "Custom AI agents and automated workflows to streamline operations and enhance customer service.",
    icon: "fa-robot"
  },
  {
    title: "Workflow Automation",
    description: "End-to-end automation solutions for business processes, integrating with popular platforms and custom systems.",
    icon: "fa-cogs"
  }
]; 